import {
    Center,
    Heading, Flex, Text, View, ScrollView, useTheme, Button
} from "native-base";
//import {CameraView, useCameraPermissions} from 'expo-camera';
import * as React from "react";
import Layout from "../layouts/Layout";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import AccountService from "../services/AccountService";
import {isAfter} from "date-fns";
import {useSelector} from "react-redux";
import {Linking, Platform} from "react-native";
import Config from "../config.json";
//Icons
import {MaterialCommunityIcons} from '@expo/vector-icons';
import {FontAwesome5} from '@expo/vector-icons';
import {useIsFocused} from "@react-navigation/native";

export default function GeneralInfoScreen({navigation}) {

    /*
    const [facing, setFacing] = useState('back');
    const [permission, requestPermission] = useCameraPermissions();

    if (!permission) {
//
    }

    if (!permission.granted) {
//
    }
     */


    const {t} = useTranslation();
    const {colors} = useTheme();
    const {congress} = useSelector(state => state.global);
    const isFocused = useIsFocused();
    // Ping
    useEffect(() => {
        let interval;
        if (isFocused) {
            interval = AccountService.startPing(navigation, {page: 'explore'});
        }
        return () => clearInterval(interval);
    }, [isFocused]);

    const getOpeningHour = () => {
        const days = ['Friday', 'Saturday', 'Sunday'];
        const tab = [];
        for (let i = 0; i < days.length; i++) {
            tab.push((
                <Text fontWeight={"light"} fontSize={"sm"} my={2} key={i + 1}>
                    <Text
                        fontWeight={"medium"}>{t(days[i])} : </Text>{transformTranslation('Infos_opening_hours_day_' + (i + 1))}
                </Text>
            ))
        }
        return tab;
    }

    function handlePressCongressLocation() {
        let link = Config.type == "aop" ? "" : "https://www.google.com/maps/place/Le+Palais+des+Congr%C3%A8s+de+Paris/@48.8793837,2.2814556,17z/data=!3m2!4b1!5s0x47e66f8b7003d35d:0x3f3ba6965a728e8b!4m5!3m4!1s0x47e66f8b6f0cce19:0x8eeda4074a7d84d8!8m2!3d48.8793837!4d2.2836443";
        if (Platform.OS == 'web') {
            return window.open(link, "_blank")
        } else {
            return Linking.openURL(link)
        }
    }

    function handlePressCongressWebsite() {
        let link = Config.type == "aop" ? "" : "https://www.google.com/maps/place/Le+Palais+des+Congr%C3%A8s+de+Paris/@48.8793837,2.2814556,17z/data=!3m2!4b1!5s0x47e66f8b7003d35d:0x3f3ba6965a728e8b!4m5!3m4!1s0x47e66f8b6f0cce19:0x8eeda4074a7d84d8!8m2!3d48.8793837!4d2.2836443";
        if (Platform.OS == "web") {
            return window.open(link, "_blank");
        } else {
            return Linking.openURL(link);
        }
    }

    function transformTranslation(key) {
        if (Config.type == "aop") {
            return t(key + "_aop")
        }
        return t(key);
    }

    return (
        <Layout withBackButton activePage={navigation.isFocused ? 'Explore' : ''}>

            <Center px={4} mb={1}>
                <Flex w={'100%'} maxW={900} align={{base: 'flex-start', md: 'center'}}>
                    <Heading size={'xl'}>{t('General_information')}</Heading>
                </Flex>
            </Center>

            <ScrollView h={'100%'}>
                <Center>
                    <Flex direction={"column"} width={{base: "100%", lg: "75%"}} justifyContent={"center"}>
                        <View backgroundColor={colors['backgroundLight']['200']} borderRadius="lg" m={2}>
                            <Button width={"auto"}
                                    mb={4}
                                    borderBottomLeftRadius={0}
                                    borderBottomRightRadius={0}
                                    onPress={handlePressCongressLocation}
                            >
                                <Center pb={2}><Text  lineHeight={"3xl"}><MaterialCommunityIcons name="map-marker-radius" size={34}
                                                                             color="white"/></Text></Center>
                                <Text textAlign={"center"} fontWeight={"bold"} fontSize={{base: "sm", lg: "md"}}
                                      color={'white'}>{transformTranslation("congress_place")}</Text>
                                <Text textAlign={"center"} fontSize={{base: "xs", lg: "sm"}}
                                      color={'white'}>{transformTranslation("congress_address_full")}</Text>
                            </Button>
                            {/*Content for Access Venue*/}
                            <Text fontSize={'2xl'} fontWeight={"bold"} px={4} mb={4} color={'white'}  lineHeight={"2xl"}><FontAwesome5
                                name="route" size={34} color="white"/> {t('Access_venue')}</Text>
                            <View backgroundColor={colors['backgroundLight']['50']} borderRadius={"md"}>
                                <Flex m={3}>
                                    <Text  lineHeight={'2xl'}><MaterialCommunityIcons name="bus-stop" size={28}
                                                                  color="#1F2357"/> {t('Bus')} : </Text>
                                    <Text fontWeight={"light"} fontSize={{
                                        base: "sm",
                                        lg: "md"
                                    }}>{t('Line') + " " + transformTranslation('Bus_info')} </Text>
                                </Flex>
                                <Flex m={3}>
                                    <Text lineHeight={'2xl'}><MaterialCommunityIcons name="subway" size={28}
                                                                  color="#1F2357"/> {t('Metro_subway')} :</Text>
                                    <Text fontWeight={"light"} fontSize={{
                                        base: "sm",
                                        lg: "md"
                                    }}>{t('Line') + " " + transformTranslation('Metro_subway_info') + " " + t('exit')}
                                    </Text>
                                </Flex>
                                <Flex m={3}>
                                    <Text  lineHeight={'2xl'}><MaterialCommunityIcons name="train-variant" size={28}
                                                                  color="#1F2357"/> {t('RER_suburban_trains')} :</Text>
                                    <Text fontWeight={"light"} fontSize={{
                                        base: "sm",
                                        lg: "md"
                                    }}>{t('Line') + " " + transformTranslation('RER_suburban_trains_info') + " " + t('exit')}
                                    </Text>
                                </Flex>
                                <Flex m={3}>
                                    <Text  lineHeight={'2xl'}><MaterialCommunityIcons name="car" size={28}
                                                                  color="#1F2357"/> {t('Car')} :</Text>
                                    <Text fontWeight={"light"} fontSize={{
                                        base: "sm",
                                        lg: "md"
                                    }}>{transformTranslation('Infos_access_car_infos')}
                                    </Text>
                                </Flex>
                            </View>
                        </View>

                        <View backgroundColor={colors['backgroundLight']['200']} borderRadius="lg" m={2}>
                            <Text fontSize={'2xl'} fontWeight={"bold"} mb={4} mt={4} px={4}
                                  color={'white'}><FontAwesome5 name="door-open" size={28}
                                                                color="white"/> {t('Opening_hours')}</Text>
                            <View backgroundColor={colors['backgroundLight']['50']} borderRadius={"md"} p={3}>
                                <Text mb={2}>
                                    {t('Infos_opening_hours_description')}
                                </Text>
                                <View ml={4} fontWeight={"light"} fontSize={"sm"}>
                                    <Text fontWeight={'light'}
                                          fontSize={'sm'}>{transformTranslation('Pick_up_info')}</Text>
                                    {getOpeningHour()}
                                </View>
                            </View>
                        </View>

                        {congress.has_gala ?
                            <View backgroundColor={colors['backgroundLight']['200']} borderRadius="lg" m={2}>
                                <Text fontSize={'2xl'} fontWeight={"bold"} my={4} px={4} color={'white'}><FontAwesome5
                                    name="wine-glass" size={28} color="white"/> {t('Gala_dinner')}</Text>
                                <View backgroundColor={colors['backgroundLight']['50']} borderRadius={"md"} p={3}>

                                    <Text fontWeight={"light"} fontSize={"sm"} my={2}>
                                        <Text
                                            fontWeight={"medium"}>{t('When')}: </Text>{transformTranslation('Gala_dinner_when_info')}
                                    </Text>
                                    <Text fontWeight={"light"} fontSize={"sm"} my={2}>
                                        <Text
                                            fontWeight={"medium"}>{t('Where')}: </Text>{transformTranslation('Gala_dinner_where_info')}
                                    </Text>
                                    <Text fontWeight={"light"} fontSize={"sm"} my={2}>
                                        <Text
                                            fontWeight={"medium"}>{t('Theme')}: </Text>{transformTranslation('Gala_dinner_theme_info')}
                                    </Text>
                                    <Text fontWeight={"light"} fontSize={"sm"} my={2}>
                                        <Text
                                            fontWeight={"medium"}>{t('Ticket')}: </Text>{transformTranslation('Gala_dinner_ticket_info')}
                                    </Text>
                                    <Text fontWeight={'light'}
                                          fontSize={'sm'}>{transformTranslation('Gala_dinner_bonus_info')}</Text>
                                </View>
                            </View>
                            :
                            <></>
                        }
                        <View backgroundColor={colors['backgroundLight']['200']} borderRadius="lg" m={2}>
                            <View flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Text fontSize={'2xl'} fontWeight={"bold"} mb={4} mt={4} px={4}
                                      color={'white'}><FontAwesome5 name="info-circle" size={28}
                                                                    color="white"/> {t('Additional_information')}</Text>
                            </View>
                            <View backgroundColor={colors['backgroundLight']['50']} borderRadius={"md"}>
                                <Flex m={3}>
                                    <Text  lineHeight={'2xl'}><MaterialCommunityIcons name="silverware" size={28}
                                                                  color="#1F2357"/> {t('Lunch')} : </Text>
                                    <Text fontWeight={"light"}
                                          fontSize={{base: "sm", lg: "md"}}>{t('Lunch_info')}</Text>
                                </Flex>
                                <Flex m={3}>
                                    <Text  lineHeight={'2xl'}><MaterialCommunityIcons name="hanger" size={28}
                                                                  color="#1F2357"/> {t('Cloackroom')} : </Text>
                                    <Text fontWeight={"light"}
                                          fontSize={{base: "sm", lg: "md"}}> {t('Cloackroom_info')}</Text>
                                </Flex>
                            </View>
                        </View>

                        <View backgroundColor={colors['backgroundLight']['200']} borderRadius="lg" m={2}>
                            {
                                isAfter(new Date(), congress.date_end) ?
                                    <Button width={"auto"}
                                            mb={4}
                                            borderBottomLeftRadius={0}
                                            borderBottomRightRadius={0}
                                            onPress={handlePressCongressWebsite}
                                    >
                                        <Center pb={2} ml={0} pl={0}><FontAwesome5 name="file-download" size={34}
                                                                                   color="#1F2357"/></Center>
                                        <Text textAlign={"center"} fontWeight={"bold"} fontSize={{
                                            base: "sm",
                                            lg: "md"
                                        }}>{t("Download")} {t("Certificate")}</Text>
                                    </Button> : null
                            }

                            <View>
                                <Text fontSize={'2xl'}
                                      fontWeight={"bold"}
                                      mb={4}
                                      m={!isAfter(new Date(), congress.date_end) ? 4 : "auto"}
                                      color={'white'}
                                >
                                    <MaterialCommunityIcons name="file-certificate" size={34} color="white"/>
                                    {t('Certificate')}
                                </Text>
                                <Flex direction={"column"} backgroundColor={colors['backgroundLight']['50']}
                                      borderRadius={"md"} p={3}>
                                    <Text>
                                        {transformTranslation('Infos_certificate_explication')}
                                    </Text>
                                </Flex>
                            </View>
                        </View>
                    </Flex>
                </Center>
            </ScrollView>


        </Layout>
    );
}
